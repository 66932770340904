import React from 'react'

import { AiFillInstagram, AiFillYoutube } from "react-icons/ai"
import { FaLinkedinIn } from "react-icons/fa"
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  const links = [
    {
      label: t('Regenic'),
      url: 'https://www.regenic.co.id',
    },
    {
      label: t('Kalgeninnolab'),
      url: 'https://www.kalgeninnolab.co.id',
    },
    {
      label: t('Pharmametriclabs'),
      url: 'https://www.pharmametriclabs.com',
    },
    { label: t('Kalgendna'), url: 'https://www.kalgendna.co.id'},
    {
      label: t('Innogene Kalbiotech'),
      url: 'https://www.innogene-kalbiotech.com',
    },
    { label: t('Kalbio'), url: 'https://www.kalbio.co.id' },
    { label: t('Kg Biologics'), url: 'https://www.kg-biologics.com/' },
  ]

  const footerContent = [
    {
      label: <AiFillInstagram size={24} />,
      url: 'https://instagram.com/growwithbifarma?igshid=YmMyMTA2M2Y=',
    },
    // {
    //   label: <AiFillYoutube size={24} />,
    //   url: 'https://instagram.com/growwithbifarma?igshid=YmMyMTA2M2Y=',
    // },
    {
      label: <FaLinkedinIn size={24} />,
      url: 'https://www.linkedin.com/company/pt-bifarma-adiluhung/',
    },
  ]

  return (
    <footer className="flex flex-col bg-light-blue">
      <div className="container w-full py-6 md:py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 pb-4">
          <div className="mb-6 md:mb-0 col-span-1">
            <div className="flex items-center justify-center sm:justify-start">
              <img
                src="/images/bifarma_logo.png"
                className="sm:w-36 mr-3 w-32"
                alt="Bifarma Logo"
              />
            </div>
            <div className="flex items-center justify-center sm:justify-start">
              <hr className="sm:w-20 w-44 mx-0 my-4 h-1 bg-dark-blue rounded-md items-center justify-center align-middle sm:justify-start"></hr>
            </div>
            <div className="flex items-center justify-center  sm:justify-start text-dark-blue">
              <h1 className="sm:w-96 w-80 sm:text-base text-xs text-center md:text-start">
                {t('KBIC')}<br/>
                {t('Address Detail')}
              </h1>
            </div>
          </div>
          <div className="text-xs gap-8 self-center justify-center sm:justify-items-end sm:items-end sm:text-base">
            <div className="flex items-center md:justify-end justify-center">
              <ul className="text-dark-blue dark:text-gray-400 font-medium item grid grid-cols-1 lg:grid-cols-2 gap-4">
                {links.map(link => (
                  <li>
                    <a href={link.url} className="hover:underline" target="_blank" rel='noreferrer'>
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex gap-8 sm:justify-end h-full">
          <div className="flex items-center justify-center gap-4 sm:justify-end  w-full h-full">
            {footerContent.map(icon => (
              <a
                href={icon.url}
                target="_blank"
                rel='noreferrer'
                className="text-white w-10 sm:w-12 h-full items-center bg-dark-blue rounded-full hover:bg-normal-blue transition-colors duration-300 p-2 sm:p-3"
              >
                {icon.label}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="sm:py-8 py-4 bg-dark-blue">
        <div className='container sm:flex sm:items-center sm:justify-between'>
          <span className="sm:text-sm text-white sm:text-center text-xs">
            {t('Copyright')} © 2023 Bifarma Adiluhung. {t('All Rights Reserved')}
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
