import React from 'react'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import { useTranslation } from 'react-i18next'
import Button from '../common/Button'

const CareerDetailSection = ({post}) => {
    const renderOptions = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => {
                if(children[0] === "") return <></>
                return <h1 className="text-xl md:text-3xl font-bold mb-2 text-dark">{children}</h1>
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                if(children[0] === "") return <></>
                return <h2 className="text-lg md:text-2xl font-bold mb-2 text-dark">{children}</h2>
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                if(children[0] === "") return <></>
                return <h3 className="text-base md:text-xl font-bold mb-2 text-dark">{children}</h3>
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
                if(children[0] === "") return <></>
                return <p className="text-sm md:text-base mb-4 text-dark text-opacity-80">{children}</p>
            },
            [BLOCKS.HR]: () => {return <hr/>},
            [INLINES.HYPERLINK]: (node, children) => {
                return (
                  <a className="text-blue-700 hover:underline" href={node.data.uri}>
                    {node.content[0].value}
                  </a>
                )
            },
        },
        renderMark: {
            [MARKS.BOLD]: text => <b>{text}</b>,
            [MARKS.ITALIC]: text => <i>{text}</i>,
        },
    }

    const { t } = useTranslation()
    
  return (
    <section className="container flex justify-center">
      <div className="grid grid-cols-1 lg:grid-cols-12 max-w-6xl px-4 py-6 md:px-6 md:py-8 my-8 md:my-12 bg-white shadow-lg rounded-md">
        <div className='block lg:hidden'>
          <p className="text-dark-blue font-bold text-xl md:text-2xl">
            {post.title}
          </p>
          <p className="text-dark-blue/80 text-sm md:text-base mt-1">
            {post.category.name} | {post.level}
          </p>
          <p className="text-dark-blue/80 text-sm md:text-base mb-4">
            {t('Location')} {post.location}
          </p>
        </div>
        <div className='col-span-8 pr-8'>
          <p className="text-dark/80 text-sm md:text-base">
            {t('Jobs Details Desc')}
          </p>
          <hr class="w-full h-px my-8 border-dark/50"/>
          {post.content && <div className='markdown'>{renderRichText(post.content, renderOptions)}</div>}
        </div>
        <div className='col-span-4'>
          <div className='hidden lg:block'>
            <p className="text-dark-blue font-bold text-xl md:text-2xl">
              {post.title}
            </p>
            <p className="text-dark-blue/80 text-sm md:text-base mt-2">
              {post.category.name} | {post.level}
            </p>
            <p className="text-dark-blue/80 text-sm md:text-base mb-4">
              {t('Location')} {post.location}
            </p>
          </div>
          <a href={post.url} target='_blank' rel='noreferrer'>
            <Button size='sm' fullWidth>{t('Apply Now')}</Button>
          </a>
          <p className="text-dark text-xs md:text-sm mt-4 text-center">
            {t('Mind')}
          </p>
          <Link to="/career#jobs">
            <p className="text-dark-blue text-xs md:text-sm text-center mt-2 hover:underline">
              {t('See other jobs')}
            </p>
          </Link>
        </div>
      </div>
    </section>
    
  )
}

export default CareerDetailSection