import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const Breadcrumb = ({ links }) => {
  const { t } = useTranslation()
  return (
    <p className="text-white font-medium text-sm md:text-base">
      {links.map((link, index) =>
        index !== links.length - 1 ? (
          <span>
            <Link to={link.url} className="hover:underline">
              {t(link.label)}
            </Link>{' '}
            &gt;&nbsp;
          </span>
        ) : (
          <span className="cursor-default text-normal-blue">
            {t(link.label)}
          </span>
        )
      )}
    </p>
  )
}

export default Breadcrumb
