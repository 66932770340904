import React from 'react'
import { useTranslation } from 'react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'

import Breadcrumb from './Breadcrumb'

const PageBanner = ({ title, links = null, bannerImage = null }) => {
  const { t } = useTranslation()
  return (
    <div className="container relative w-full h-48 md:h-60 bg-gray-700 flex justify-center items-center">
      <div className="flex flex-col items-center gap-2 z-20">
        <h2 className="text-white text-2xl md:text-4xl text-center">{t(title)}</h2>
        {links && <Breadcrumb links={links}/>}
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-dark bg-opacity-[0.65] z-10"></div>
      {bannerImage && (
        <div className="absolute top-0 left-0 w-full h-full">
          <GatsbyImage image={bannerImage} className="w-full h-full" />
        </div>
      )}
    </div>
  )
}

export default PageBanner
