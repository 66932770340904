import React from 'react'
import { graphql } from 'gatsby'

import { Seo as SEO } from '../../components/Seo'
import CareerDetailSection from '../../components/career/CareerDetailSection'
import PageBanner from '../../components/common/PageBanner'
import Footer from '../../components/common/Footer'

const CareerDetail = ({data}) => {
    const { post, banner } = data
    const links = []

    return (
        <section>
            <PageBanner title={"discover your dream job and let's #GrowTogether"} links={links} bannerImage={banner.gatsbyImageData}/>
            <CareerDetailSection post={post}/>
            <Footer/>
        </section>
    )
}

export default CareerDetail 

export const Head = ({ data }) => {
    return (
      <SEO
        title={data.post.title}
        description={
          'Join Bifarma Adiluhung and contribute to our mission of enhancing lives through preventive, promotive, and predictive treatments. Explore rewarding career opportunities in the dynamic biopharmaceutical sector.'
        }
      />
    )
}

export const query = graphql`
    query getCareerDetail($language: String!, $slug: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
            node {
                ns
                data
                language
            }
            }
        }
        post: contentfulJobPosition(slug: { eq: $slug }) {
            id
            category{
                name
            }
            title
            level
            url
            publishDate(formatString: "DD/MM/YYYY")
            content {
                raw
            }
            location
        }
        banner: contentfulAsset(title: { eq: "Page Banner Placeholder" }) {
            gatsbyImageData
        }
    }
`